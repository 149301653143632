import React, { useState, useEffect, useContext, Component } from 'react';
import AccountSidebar from '../components/AccountSidebar';
import { toast } from 'react-hot-toast';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Account = () => {


    const getUsernameAndEmailFromLocalStorage = () => {
        const userString = localStorage.getItem('user');
        if (userString) {
            const { username, email } = JSON.parse(userString);
            return { username, email };
        }
        return null;
    };

    // Retrieve username and email from local storage user data
    const { username = null, email = null } = getUsernameAndEmailFromLocalStorage() || {};

    const [Ordercart, setOrderCart] = useState({});


    useEffect(() => {
        const userCart = localStorage.getItem('react-use-cart');
        if (userCart) {
            const { items } = JSON.parse(userCart);
            setOrderCart(items);
        }
    }, []); // Empty dependency array to run this effect only once when the component mounts


    console.log('Ordercart', Ordercart)
    return (
        <>

            <Header />
            <section id="mid">
                <div className="container mt-3">
                    <div className="row">
                        <AccountSidebar />
                        <div className="col-md-9">
                            <h2>Dashboard</h2>

                            <p>
                                Hello <b>{username} </b> (not {username}? Log out)

                                From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and edit your password and account details.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />


        </>
    )
}

export default Account